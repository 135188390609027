import React from "react";
import "../../App.css";
import Form from "../Form";
import Footer from "../Footer";

function Home() {
  return (
    <>
      <div className="sign-up">
        <Form />
      </div>
      <Footer />
    </>
  );
}
export default Home;
