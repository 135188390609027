import React from "react";
import "../../App.css";
import Cards from "../Cards";
import Footer from "../Footer";
function Home() {
  return (
    <>
      <div className="products">
        <Cards />
      </div>
      <Footer />
    </>
  );
}
export default Home;
